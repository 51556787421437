import KeenSliderHelper from '~/classes/keen-slider-helper'
import { getSvgSpriteIcon } from '~/helpers/get-svg-sprite-icon'

let sliderHelperInstance = {} as KeenSliderHelper

window.addEventListener('DOMContentLoaded', () => {
    initSlider()
}, {once: true})

const initSlider = () => {
    const activeSliderContainer = document.querySelector<HTMLElement>('.brands-slider__content-wrapper')
    if (!activeSliderContainer) return void 0
    const activeSliderElement = activeSliderContainer.querySelector<HTMLElement>('.keen-slider')
    if (!activeSliderElement) return void 0

    sliderHelperInstance = new KeenSliderHelper(
        activeSliderElement,
        {
            slides: {
                perView: 1,
            },
            defaultAnimation: {
                duration: 50,
            },
            breakpoints: {
                '(min-width: 640px)': {
                    slides: {
                        perView: 3,
                        perScroll: 3
                    }
                },
                '(min-width: 960px)': {
                    slides: {
                        perView: 4,
                        perScroll: 4
                    }
                },

                '(min-width: 1440px)': {
                    slides: {
                        perView: 6,
                        perScroll: 6
                    }
                },
            }
        }
    )

    const arrowHtml = `<svg><use href="${getSvgSpriteIcon('icon-arrow-min')}"></use></svg>`
    sliderHelperInstance.createAdditionalMarkup({
        arrows: {
            enable: true,
            html: arrowHtml
        },
        dots: {
            enable: false
        },
        counter: {
            enable: false
        }
    })
    activeSliderElement.classList.remove('opacity-0')
}

