import 'keen-slider/keen-slider.min.css'

import KeenSliderHelper from '~/classes/keen-slider-helper'
import { keenSliderPluginAutoSwitch } from '~/classes/keen-slider-plugins/keen-slider-plugin-auto-switch'
import { getSvgSpriteIcon } from '~/helpers/get-svg-sprite-icon'

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        const container = document.getElementById('banners-middle') as HTMLElement

        if (!container) return void 0

        const slider = new KeenSliderHelper(
            container,
            {
                loop: true,
                plugins: [keenSliderPluginAutoSwitch()]
            },
        )

        const arrowHtml = `<svg><use href="${getSvgSpriteIcon('icon-arrow-min')}"></use></svg>`

        slider.createAdditionalMarkup({
            arrows: {
                enable: true,
                html: arrowHtml
            },
            dots: {
                enable: true
            },
            counter: {
                enable: false
            },
            popup: {
                enable: true,
                selector: '.slider-banners__adv-button'
            }
        })

        container.classList.remove('opacity-0')
    })
}, {once: true})
