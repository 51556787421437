import 'keen-slider/keen-slider.min.css'
import KeenSliderHelper from '~/classes/keen-slider-helper'
import { getSvgSpriteIcon } from '~/helpers/get-svg-sprite-icon'

import { googleDataMarketingBannerClick  } from '~/helpers/google-datalayer'

window.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        const selectorList = ['banners-type1', 'banners-type2', 'banners-type3']

        selectorList.forEach(selector => {
            const container = document.getElementById(selector) as HTMLElement

            if (!container) return void 0

            const slider = new KeenSliderHelper(
                container,
                {
                    loop: true,
                }
            )

            const arrowHtml = `<svg><use href="${getSvgSpriteIcon('icon-arrow-min')}"></use></svg>`

            slider.createAdditionalMarkup({
                arrows: {
                    enable: true,
                    html: arrowHtml
                },
                dots: {
                    enable: true
                },
                counter: {
                    enable: false
                },
                popup: {
                    enable: true,
                    selector: '.slider-banners__adv-button'
                },
            })

            switch (selector) {
            case 'banners-type1':
                autoplayAtDifferentIntervals(slider, 5000, 15000)
                break
            case 'banners-type2':
                autoplayAtDifferentIntervals(slider, 10000, 15000)
                break
            case 'banners-type3':
                slider.autoplay(15000)
                break
            }

            initClickListener(container)

            container.classList.remove('opacity-0')
        })
    })
}, {once: true})

const initClickListener = (target: HTMLElement) => {
    target.addEventListener('click', handleClick)
}

const handleClick = (e: Event) => {
    const eventTarget = e.target as HTMLElement
    const currentSlide = eventTarget.closest<HTMLElement>('.slider-banners__slide')

    if (currentSlide) {
        googleDataMarketingBannerClick(currentSlide)
    }
}

const autoplayAtDifferentIntervals = (slider: KeenSliderHelper, interval1: number, interval2: number) => {
    slider.autoplay(interval1)

    setTimeout(() => {
        slider.autoplayReset()
        slider.autoplay(interval2)
    }, interval1)
}
