let selectedCategoryMemorized: HTMLElement | null = null
let accordionCategoriesContainer: HTMLElement | null = null

window.addEventListener('DOMContentLoaded', () => {
    accordionCategoriesContainer = document.querySelector<HTMLElement>('.accordion-categories')

    if (!accordionCategoriesContainer) return void 0

    handleAccordionSections()
    handleLinksClick()
}, {once: true})

const handleAccordionSections = () => {
    const accordionCategories = document.querySelectorAll<HTMLElement>('.accordion-categories__section')
    selectedCategoryMemorized = document.querySelector<HTMLElement>('.accordion-categories__section--selected')

    if (!accordionCategories.length) return void 0

    accordionCategories.forEach(el => el.addEventListener('click', switchActiveAccordionCategory.bind(null, el)))
}

const switchActiveAccordionCategory = (selectedCategory: HTMLElement) => {
    if (selectedCategoryMemorized) {
        selectedCategoryMemorized.classList.remove('accordion-categories__section--selected')
    }

    selectedCategory.classList.add('accordion-categories__section--selected')
    selectedCategoryMemorized = selectedCategory
}

const handleLinksClick = () => {
    accordionCategoriesContainer?.addEventListener('click', (e: Event) => {
        const target = e.target as HTMLElement

        if (!target.classList.contains('accordion-categories__link')) return void 0

        const groupId = target.dataset.groupId
        const categoryId = target.dataset.categoryId

        if (groupId && categoryId) {
            document.cookie = `selected-menu-group=${groupId}; path=/; max-age=2628000`
            document.cookie = `selected-menu-group-item=${categoryId}; path=/; max-age=2628000`
        }
    })
}
